import React from "react"

import { withImageContentCarousel } from "./withImageContentCarousel"
import { Wrapper, Title, ButtonWrapper } from "./ImageContentCarouselStyles"
import { Slider, Slide } from "../../Slider/Slider"
import { Next, Prev } from "../../Slider/SliderStyles"
import { HeroCard } from "../Hero/Card/HeroCard"
import { ImageGridCard } from "../ImageGrid/Card/ImageGridCard"
import { Icon } from "../../Icon/Icon"
import { ButtonLink } from "../../ButtonLink/ButtonLink"

export const ImageContentCarousel = withImageContentCarousel(
  ({
    title,
    button,
    id,
    images,
    ratio,
    itemSpacing,
    initialLoadCount,
    handlePromoClick,
  }) => (
    <Wrapper>
      {title?.length > 0 && <Title>{title}</Title>}
      <Slider
        layout={`imageContentCarousel-${initialLoadCount ?? 4}`}
        itemSpacing={itemSpacing}
        prevEl={`#id-${id}-carousel-prev`}
        nextEl={`#id-${id}-carousel-next`}
      >
        {images?.map(image => {
          if (image._type === "imageCardHero") {
            return (
              <Slide key={image._key}>
                <HeroCard
                  card={image}
                  ratio={ratio}
                  handlePromoClick={handlePromoClick}
                />
              </Slide>
            )
          }
          if (image._type === "imageCardNormal") {
            return (
              <Slide key={image._key}>
                <ImageGridCard
                  card={image}
                  ratio={ratio}
                  handlePromoClick={handlePromoClick}
                />
              </Slide>
            )
          }
        })}
      </Slider>
      <Prev id={`id-${id}-carousel-prev`}>
        <Icon name={"arrowLeft"} />
      </Prev>
      <Next id={`id-${id}-carousel-next`}>
        <Icon name={"arrow"} />
      </Next>
      {button?.link?.title && (
        <ButtonWrapper>
          <ButtonLink
            link={button?.link}
            style={button?.style}
            size={button?.size}
          />
        </ButtonWrapper>
      )}
    </Wrapper>
  ),
)

export default ImageContentCarousel
