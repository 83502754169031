import tw, { styled } from "twin.macro"
import { H6 } from "../../Styled/Text"
export const Wrapper = tw.div`
  relative
`

export const Title = styled(H6)`
  ${tw`px-2 text-center mb-2-4 md:mb-4`}
`

export const ButtonWrapper = tw.div`
  px-2 md:px-0 flex justify-center mt-0-4 md:mt-2
`
